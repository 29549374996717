
import {
  Component,
  Vue,
  Watch,
  Prop,
} from 'vue-property-decorator';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Popup from './components/popup.vue';

// import FooterInfo from './components/FooterInfo.vue';
import tFooterInfo from './components/tFooterInfo.vue';

@Component({
  name: 'mainLayout',
  components: {
    Header,
    Footer,
    tFooterInfo,
    Popup,
  },
})

export default class extends Vue {
  private heightFix = false;

  private menuList: any = [];

  private fixHeight() {
    this.heightFix = !this.heightFix;
  }

  private handleCloseDialog(isClose: boolean) {
    this.$emit('close-dialog', true);
    this.$router.push({ name: 'Home' });
    window.location.reload();
  }
}
