import request from '@/utils/request';
import { SITE_UID } from './site';

const PATH = '/menu';

export const getMenuList = () => request({
  url: `${PATH}/${SITE_UID}/list`,
  method: 'get',
});

export const getMenu = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});

export const getMenuNav = (uid: string) => request({
  url: `${PATH}/${uid}/nav`,
  method: 'get',
});
