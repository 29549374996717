import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import { checkToken, login } from '@/api/user';
import {
  getToken, getTokenDecode, setToken, removeToken,
} from '@/utils/cookies';
import {
  getNaverLogin,
  getKakaoLogin,
  getGoogleLogin,
  getAppleLogin,
} from '@/api/oauth';
import store from '@/store';
import router from '@/router';
import { Message } from 'element-ui';

export interface IUserState {
  token: string
  userId: string
  roles: string[]
  errorMessage: string
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public isLogin = false;

  public token = getToken() || ''

  public userId = ''

  public roles: string[] = []

  public errorMessage = ''

  public actualName = ''

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_USER_ID(userId: string) {
    this.userId = userId;
  }

  @Mutation
  private SET_IS_LOGIN(status: boolean) {
    this.isLogin = status;
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  @Mutation
  private SET_ACTUAL_NAME(actualName: string) {
    this.actualName = actualName;
  }

  @Mutation
  private SET_ERROR_MESSAGE(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  @Action
  public async Login(userInfo: { username: string, password: string }) {
    return new Promise((resolve, reject) => {
      const { username, password } = userInfo;
      login({ username, password }).then((res) => {
        setToken(res.data.access_token);
        this.SET_TOKEN(res.data.access_token);
        this.GetUserInfo();
        resolve('');
      }).catch((error) => {
        const data = error.response.data;
        Message.error(data.error_description || data.message);
        reject();
      });
    });
  }

  /* eslint-disable */
  @Action
  public async NaverLogin(code: string) {
    return new Promise((resolve, reject) => {
      getNaverLogin(code).then((res) => {
        if (res.data.body.access_token) {
          setToken(res.data.body.access_token);
          this.SET_TOKEN(res.data.body.access_token);
          this.GetUserInfo();
          resolve('login');
        } else if (res.data.body.code) {
          router.push({ name: 'Join', query: { code: res.data.body.code, type: 'naver' } });
          resolve('');
        }
      });
    });
  }

  @Action
  public async KakaoLogin(code: string) {
    return new Promise((resolve, reject) => {
      getKakaoLogin(code).then((res) => {
        if (res.data.body.access_token) {
          setToken(res.data.body.access_token);
          this.SET_TOKEN(res.data.body.access_token);
          this.GetUserInfo();
          resolve('login');
        } else if (res.data.body.code) {
          router.push({ name: 'Join', query: { code: res.data.body.code, type: 'kakao' } });
          resolve('');
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  @Action
  public async GoogleLogin(accessToken: string) {
    return new Promise((resolve, reject) => {
      getGoogleLogin(accessToken).then((res) => {
        if (res.data.body.access_token) {
          setToken(res.data.body.access_token);
          this.SET_TOKEN(res.data.body.access_token);
          this.GetUserInfo();
          resolve('login');
        } else if (res.data.body.code) {
          router.push({ name: 'Join', query: { code: res.data.body.code, type: 'google' } });
          resolve('');
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  @Action
  public async AppleLogin(idToken: string) {
    return new Promise((resolve, reject) => {
      getAppleLogin(idToken).then((res) => {
        if (res.data.body.access_token) {
          setToken(res.data.body.access_token);
          this.SET_TOKEN(res.data.body.access_token);
          this.GetUserInfo();
          resolve('login');
        } else if (res.data.body.code) {
          router.push({ name: 'Join', query: { code: res.data.body.code, type: 'apple' } });
          resolve('');
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  @Action
  public async GetUserInfo() {
    if (this.token) {
      const data: any = getTokenDecode();
      this.SET_ROLES(data.authorities);
      this.SET_USER_ID(data.user_name);
      this.SET_ACTUAL_NAME(data.actual_name);
      this.SET_IS_LOGIN(true);
    }
  }
  /* eslint-enable */

  @Action
  public async LogOut() {
    if ((window as any).flutter_inappwebview) {
      (window as any).flutter_inappwebview.callHandler('onLogOut');
    }
    removeToken();
    // Reset visited views and cached views
    this.SET_IS_LOGIN(false);
    this.SET_TOKEN('');
    this.SET_ROLES([]);
    this.SET_USER_ID('');
    this.SET_ACTUAL_NAME('');
  }

  @Action
  public ResetErrorMessage() {
    this.SET_ERROR_MESSAGE('');
  }
}

export const UserModule = getModule(User);
