
import { Component, Vue, Watch } from 'vue-property-decorator';
import SubHeader from './components/SubHeader.vue';
import Footer from './components/Footer.vue';
import FooterInfo from './components/FooterInfo.vue';
import tFooterInfo from './components/tFooterInfo.vue';

@Component({
  name: 'mainLayout',
  components: {
    SubHeader,
    Footer,
    tFooterInfo,
  },
})

export default class extends Vue {
  private heightFix = false;

  private menuList: any = [];

  private fixHeight() {
    this.heightFix = !this.heightFix;
  }
}
