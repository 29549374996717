import axios from 'axios';
import qs from 'qs';

export const getNaverLogin = (code: string) => {
  return axios({
    url: `${process.env.VUE_APP_OAUTH_API}/naver/login`,
    method: 'get',
    params: {
      code,
    },
  });
};

export const getKakaoLogin = (code: string) => {
  return axios({
    url: `${process.env.VUE_APP_OAUTH_API}/kakao/login`,
    method: 'get',
    params: {
      code,
    },
  });
};

export const getGoogleLogin = (accessToken: string) => {
  return axios({
    url: `${process.env.VUE_APP_OAUTH_API}/google/login`,
    method: 'get',
    params: {
      code: accessToken,
    },
  });
};

export const getAppleLogin = (idToken: string) => {
  return axios({
    url: `${process.env.VUE_APP_OAUTH_API}/apple/login`,
    method: 'get',
    params: {
      idToken,
    },
  });
};

/*
export const getPhoneLogin = (encodeData: string) => {
  return axios({
    url: `${process.env.VUE_APP_OAUTH_API}/token`,
    method: 'post',
    headers: {
      authorization: 'Basic c2luZ2hhX29hdXRoOnNpbmdoYXNjcmVjdCFAIyQ=',
      'x-auth-token': `Nice ${encodeData}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
*/

/*
export const getNaverAccessToken = (code: string) => {
  const data:any = {};
  data.grant_type = 'authorization_code';
  data.client_id = 'dMKBnwnsGp8K9EKp0c_V';
  data.client_secret = 'xMv5UopI5k';
  data.redirect_uri = `${window.location.origin}/login`;
  data.code = code;
  data.state = '123qwe123qawfweer4gb56u45';
  return axios({
    url: 'https://nid.naver.com/oauth2.0/token',
    method: 'get',
    data: qs.stringify(data),
  });
};
*/

export const getKakaoAccessToken = (code: any) => {
  const data: any = {};
  data.grant_type = 'authorization_code';
  data.client_id = process.env.VUE_APP_KAKAO_CLIENT_ID;
  data.redirect_uri = process.env.VUE_APP_KAKAO_REDIRECT_URL;
  data.code = code;

  return axios({
    url: 'https://kauth.kakao.com/oauth/token',
    method: 'post',
    data: qs.stringify(data),
  });
};
