import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';

@Module({ dynamic: true, store, name: 'mobile' })
class Mobile extends VuexModule {
  public isMobile = false;

  @Mutation
  private SET_IS_MOBILE(isMobile: boolean) {
    this.isMobile = isMobile;
  }

  @Action
  public SetMobileStatus(isMobile: boolean) {
    this.SET_IS_MOBILE(isMobile);
  }
}

export const MobileModule = getModule(Mobile);
