import moment from 'moment';
import 'moment/locale/ko';

export const parseDate = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'YYYY.MM.DD');
  return parsingDate;
};

export const parseDateD = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'DD');
  return parsingDate;
};

export const parseDateY = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'YYYY.MM');
  return parsingDate;
};

export const parseDateMonth = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'MM.DD');
  return parsingDate;
};

export const parseDateTime = (stringDate: string, format: string) => {
  const parsingDate = moment(stringDate).format(format || 'YYYY.MM.DD HH:mm');
  return parsingDate;
};

export const parseTime = (stringTime: string, format: string) => {
  const parsingTime = moment(stringTime, 'HH:mm:ss').format(format || 'HH:mm');
  return parsingTime;
};

export const parseConcatNumber = (data: string) => {
  if (data) {
    const filterDate = data.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3');
    return filterDate;
  }
  return data;
};

export const parseRegisterNo = (data: string) => {
  if (data) {
    const filterRegisterNo = data.replace(/[^0-9]/g, '').replace(/^(\d{3})(\d{2})(\d{5})$/, '$1-$2-$3');
    return filterRegisterNo;
  }
  return data;
};

export const removeTag = (value: string) => {
  const filterValue = value.replace(/(<([^>]+)>)/ig, '');
  return filterValue;
};

export const parseKrw = (value: string) => {
  const number = Number(value);
  /* eslint-disable */
  if (isNaN(number)) return value;
  return Number(number).toLocaleString('ko-KR');
};

export const numToString = (num: number | string) => {
  const number = Number(num);
  /* eslint-disable */
  if (isNaN(number)) return num;
  return Number(number).toLocaleString('ko-KR');
};

export const parseDistance = (distance: number | string) => {
  if (!distance) return '';
  const value = Number(distance);
  /* eslint-disable */
  if (isNaN(value)) return distance;
  if (value > 1000) {
    return `${(value / 1000).toFixed(0)}km`;
  }
  return `${value}m`;
};


export const parseTimeUnit = (minute: number) => {
  if (minute > 1440) return Math.floor(minute / 1440);
  return minute;
}

// 자는 일수(ex: 1박, 2박)
export const parseNightFormDateRange = (dateRange: string[]) => {
  if (!dateRange || dateRange.length != 2) return '';
  const startDate = moment(dateRange[0]);
  const endDate = moment(dateRange[1]);
  return `${moment.duration(endDate.diff(startDate)).asDays()}박`;
};

export const pareseTossBankCode = (code: String) => {
  let bankName = '';
  if (code === '39') bankName = '경남은행';
  else if (code === '34') bankName = '광주은행';
  else if (code === '12') bankName = '단위농협(지역농축협)';
  else if (code === '32') bankName = '부산은행';
  else if (code === '45') bankName = '새마을금고';
  else if (code === '64') bankName = '산림조합';
  else if (code === '88') bankName = '신한은행';
  else if (code === '48') bankName = '신협';
  else if (code === '27') bankName = '씨티은행';
  else if (code === '20') bankName = '우리은행';
  else if (code === '71') bankName = '우체국예금보험';
  else if (code === '50') bankName = '저축은행중앙회';
  else if (code === '37') bankName = '전북은행';
  else if (code === '35') bankName = '제주은행';
  else if (code === '90') bankName = '카카오뱅크';
  else if (code === '89') bankName = '케이뱅크';
  else if (code === '92') bankName = '토스뱅크';
  else if (code === '81') bankName = '하나은행';
  else if (code === '54') bankName = '홍콩상하이은행';
  else if (code === '03') bankName = 'IBK기업은행';
  else if (code === '06') bankName = 'KB국민은행';
  else if (code === '31') bankName = 'DGB대구은행';
  else if (code === '02') bankName = 'KDB산업은행';
  else if (code === '11') bankName = 'NH농협은행';
  else if (code === '23') bankName = 'SC제일은행';
  else if (code === '07') bankName = 'Sh수협은행';
  return bankName;
};

export const parseDayNum = (num: number) => {
  let day = '';
  if (num === 1) day = '월';
  else if (num === 2) day = '화';
  else if (num === 3) day = '수';
  else if (num === 4) day = '목';
  else if (num === 5) day = '금';
  else if (num === 6) day = '토';
  else if (num === 7) day = '일';
  return day;
};
