import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ko';
import VueClipboard from 'vue-clipboard2';
// import 'element-ui/lib/theme-chalk/index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faCog,
  faUserCircle,
  faChevronDown,
  faChevronUp,
  faSquare,
  faCheckSquare,
  faExpand,
  faTimes,
  faQuestion,
  faEnvelope,
  faLock,
  faPlus,
  faUpload,
  faPencilAlt,
  faEdit,
  faLevelDownAlt,
  faChevronLeft,
  faChevronRight,
  faTrashAlt,
  faExternalLinkAlt,
  faPencilRuler,
  faPaintBrush,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import * as filters from '@/filters';
import 'remixicon/fonts/remixicon.css';

import router from './router';
import store from './store';
import App from './App.vue';

(library as any).add(
  faSearch,
  faCog,
  faUser,
  faUserCircle,
  faChevronDown,
  faChevronUp,
  faSquare,
  faCheckSquare,
  faExpand,
  faTimes,
  faQuestion,
  faEnvelope,
  faLock,
  faPlus,
  faUpload,
  faPencilAlt,
  faEdit,
  faLevelDownAlt,
  faChevronLeft,
  faChevronRight,
  faTrashAlt,
  faExternalLinkAlt,
  faPencilRuler,
  faPaintBrush,
);

Vue.config.productionTip = false;

Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI, { locale });
Vue.use(VueClipboard);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, (filters as { [key: string ]: Function })[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

const viewportWidth: number = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

// 뷰포트 너비가 768px 이상인 경우
if (viewportWidth >= 768) {
  // 뷰포트 설정 변경
  const metaViewport = document.querySelector('meta[name="viewport"]');
  if (metaViewport) {
    metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
  }
}
