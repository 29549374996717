
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  private activeIndex = '1';

  private activeIndex2 = '1';

  private activeMenu = false;

  get currentImage(): string {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 7 && hour < 18) {
      return '/img/footer-menu-icon05.svg';
    }
    if (hour >= 18 && hour < 20) {
      return '/img/footer-menu-icon06.svg';
    }
    if (hour >= 20 && hour < 24) {
      return '/img/footer-menu-icon07.svg';
    }
    if (hour >= 0 && hour < 4) {
      return '/img/footer-menu-icon07.svg';
    }
    if (hour >= 4 && hour < 7) {
      return '/img/footer-menu-icon08.svg';
    }
    return '';
  }

  get currentColor(): string {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 7 && hour < 18) {
      return 'color07';
    }
    if (hour >= 18 && hour < 20) {
      return 'color18';
    }
    if (hour >= 20 && hour < 24) {
      return 'color20';
    }
    if (hour >= 0 && hour < 4) {
      return 'color20';
    }
    if (hour >= 4 && hour < 7) {
      return 'color4';
    }
    return '';
  }

  mounted() {
    this.addColorClass();
  }

  private addColorClass() {
    const hour = new Date().getHours();
    let className = '';

    if (hour >= 7 && hour < 18) {
      className = 'color07';
    } else if (hour >= 18 && hour < 20) {
      className = 'color18';
    } else if (hour >= 20 && hour < 24) {
      className = 'color20';
    } else if (hour >= 0 && hour < 4) {
      className = 'color20';
    } else if (hour >= 4 && hour < 7) {
      className = 'color4';
    }

    const elements = this.$el.querySelectorAll('.el-submenu__title');
    elements.forEach((element) => {
      const htmlElement = element as HTMLElement;
      htmlElement.classList.add(className);
    });
  }

  private closeDialog() {
    this.$emit('close-dialog', true);
  }

  private handleMenuItemClick() {
    this.$router.push('/');
  }
}
