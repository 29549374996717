
import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import SubHeader from './components/mypageHeader.vue';
import Footer from './components/Footer.vue';
import FooterInfo from './components/FooterInfo.vue';
import tFooterInfo from './components/tFooterInfo.vue';

@Component({
  name: 'MypageLayout',
  components: {
    SubHeader,
    Footer,
    FooterInfo,
    tFooterInfo,
  },
})

export default class extends Vue {
  created() {
    if (!UserModule.token) {
      this.$router.push({ name: 'Login' });
    }
  }

  private heightFix = false;

  private menuList: any = [];

  private fixHeight() {
    this.heightFix = !this.heightFix;
  }
}
