
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getMenu, getMenuList } from '@/api/menu';
import { IMenuList } from '@/types';
import { MenuModule } from '@/store/menu';
import { UserModule } from '@/store/user';

@Component({
  name: 'mainLayout',
  components: {
  },
})

export default class extends Vue {
  @Watch('$route')
  private closeSiteMap() {
    if (this.siteMapActive) this.handleSiteMap();
    if (this.mobileMenuVisible) this.handleMobileMenu();
  }

  get menuList() {
    return MenuModule.menu;
  }

  get isLogin() {
    return UserModule.isLogin;
  }

  private goBack() {
    this.$router.go(-1);
  }

  private siteMapActive = false;

  private mobileMenuVisible = false;

  private searchFormVisible = false;

  private handleSearchForm() {
    this.searchFormVisible = !this.searchFormVisible;
  }

  private getLink(link: string) {
    if (link) {
      if (!link.startsWith('http')) return `http://${link}`;
      return link;
    }
    return '#';
  }

  private handleClickLogout() {
    UserModule.LogOut();
    this.$router.go(0);
  }

  private getRouter(menu: any) {
    if (menu.children.length > 0) {
      if (menu.children[0].children.length > 0) {
        menu = menu.children[0].children[0];
      } else menu = menu.children[0];
    }

    const router = {
      name: '',
      params: { menuUid: menu.uid },
    };
    if (menu.menuType === 'BOARD') router.name = 'BoardIndex';
    if (menu.menuType === 'CONTENTS') router.name = 'ContentsIndex';
    if (menu.menuType === 'RESERVATION') router.name = 'ReservationIndex';
    if (menu.menuType === 'MODULE') router.name = menu.moduleName;
    return router;
  }

  private handleSiteMap() {
    this.siteMapActive = !this.siteMapActive;
    if (!this.mobileMenuVisible) this.$emit('fix-height');
  }

  private handleMobileMenu() {
    this.mobileMenuVisible = !this.mobileMenuVisible;
    if (!this.siteMapActive) this.$emit('fix-height');
  }

  get currentImage(): string {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 7 && hour < 18) {
      return '/img/new-mobile-header-logo01.svg';
    }
    if (hour >= 18 && hour < 20) {
      return '/img/new-mobile-header-logo02.svg';
    }
    if (hour >= 20 && hour < 24) {
      return '/img/new-mobile-header-logo03.svg';
    }
    if (hour >= 0 && hour < 4) {
      return '/img/new-mobile-header-logo03.svg';
    }
    if (hour >= 4 && hour < 7) {
      return '/img/new-mobile-header-logo04.svg';
    }
    return '';
  }

  private handleLogout() {
    UserModule.LogOut();
    this.$router.go(0);
  }
}
