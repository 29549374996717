import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

// User
const tokenKey = 'access_token';
export const getTokenDecode = () => {
  return jwtDecode(cookies.get(tokenKey) || '');
};

export const getToken = () => cookies.get(tokenKey);
export const setToken = (token: string) => {
  if ((window as any).flutter_inappwebview) {
    (window as any).flutter_inappwebview.callHandler('onAccessToken', token);
  }
  cookies.set(tokenKey, token);
};
export const removeToken = () => cookies.remove(tokenKey);
export const setRedirectUrl = (redirectUrl: string) => cookies.set('redirectUrl', redirectUrl);
export const getRedirectUrl = () => cookies.get('redirectUrl');
export const removeRedirectUrl = () => cookies.remove('redirectUrl');

const visitKey = `visitHistory${moment().format('YYYYMMDD')}`;
export const getVisitKey = () => cookies.get(visitKey);
export const setVisitKey = () => cookies.set(visitKey, '1', { expires: 1 });

export const setReservation = (data: any) => cookies.set('reservation', JSON.stringify(data));
export const getReservation = () => JSON.parse(cookies.get('reservation'));

export const setStayReservation = (data: any) => cookies.set('stay-reservation', JSON.stringify(data));
export const getStayReservation = () => JSON.parse(cookies.get('stay-reservation'));

export const getTokenInfo = () => {
  const token: any = getTokenDecode();
  return token;
};

export const getJwtInfo = (key: string) => {
  return getTokenInfo()[key];
};

export const getLat = () => cookies.get('lat');
export const getLng = () => cookies.get('lng');
export const setLat = (lat: any) => cookies.set('lat', lat);
export const setLng = (lng: any) => cookies.set('lng', lng);

export const getLoginType = () => cookies.get('loginType');
export const setLoginType = (loginType: any) => cookies.set('loginType', loginType);
export const getLoginCode = () => cookies.get('loginCode');
export const setLoginCode = (loginCode: any) => cookies.set('loginCode', loginCode);
