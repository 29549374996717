
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'loginLayout',
})

export default class extends Vue {
  private heightFix = false;

  private menuList: any = [];

  private fixHeight() {
    this.heightFix = !this.heightFix;
  }
}
