import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { getMenuList } from '@/api/menu';

@Module({ dynamic: true, store, name: 'menu' })
class Menu extends VuexModule {
  public menu: any = [];

  public category: any = [];

  @Mutation
  private SET_MENU(menu: any) {
    this.menu = menu;
    this.menu = this.menu.map((object: any) => {
      return { ...object, mactive: false };
    });
  }

  @Action
  public GetMenu() {
    getMenuList().then((res) => {
      this.SET_MENU(res.data);
    });
  }
}

export const MenuModule = getModule(Menu);
