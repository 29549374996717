
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'PopupComponent',
  setup() {
    const showPopup = ref(false);
    const doNotShowToday = ref(false);
    const imageLoaded = ref(false); // 이미지 로드 상태를 관리
    const isMobile = ref(false); // 모바일체크

    const checkPopupStatus = () => {
      const savedDate = localStorage.getItem('hidePopupDate');
      const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD 형식으로 변환
      if (savedDate !== today) {
        showPopup.value = true;
      }
    };

    const closePopup = () => {
      showPopup.value = false;
    };

    const closePopup2 = () => {
      const today = new Date().toISOString().split('T')[0];
      localStorage.setItem('hidePopupDate', today);
      showPopup.value = false;
    };

    // 이미지가 로드 완료되었을 때 호출
    const onImageLoad = () => {
      imageLoaded.value = true;
    };
    onMounted(() => {
      checkPopupStatus();
      if (window.innerWidth < 700) {
        isMobile.value = true;
      }
    });

    return {
      showPopup,
      doNotShowToday,
      closePopup,
      closePopup2,
      imageLoaded,
      onImageLoad,
      isMobile,
    };
  },
});
