import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import MobileLayout from '@/layout/Mobile/index.vue';
import MobileSubLayout from '@/layout/Mobile/sub.vue';
import MobilePageLayout from '@/layout/Mobile/page.vue';
import MobileLoginLayout from '@/layout/Mobile/login.vue';
import MoblileIndex from '@/layout/Mobile/mypageIndex.vue';
import MobileMypageLayout from '@/layout/Mobile/mypage.vue';
import TabletLayout from '@/layout/Mobile/tablet.vue';
import { UserModule } from '@/store/user';
import { checkToken } from '@/api/user';
import SubDetail from '@/layout/Mobile/subdetail.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: MobileLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/index/index.vue'),
        name: 'Home',
      },
    ],
  },
  {
    path: '/login',
    component: MobileLoginLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/login/login.vue'),
        name: 'Login',
        meta: {
          name: '로그인',
        },
      },
    ],
  },
  {
    path: '/login',
    component: MobilePageLayout,
    children: [
      {
        path: '/join',
        component: () => import('@/views/login/join.vue'),
        name: 'Join',
        meta: {
          name: '회원가입',
        },
      },
    ],
  },
  {
    path: '/stay',
    component: MobileSubLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/stay/index.vue'),
        name: 'StayIndex',
        meta: {
          name: '숙박',
        },
      },
    ],
  },
  {
    path: '/stay',
    component: SubDetail,
    children: [
      {
        path: ':stayIdx/detail',
        component: () => import('@/views/stay/view.vue'),
        name: 'StayDetail',
        meta: {
          name: '숙박',
        },
      },
      {
        path: 'room/:roomIdx',
        component: () => import('@/views/stay/room.vue'),
        name: 'RoomDetail',
        meta: {
          name: '숙박',
        },
      },
      {
        path: 'room/:roomIdx/reserve',
        component: () => import('@/views/stay/reserve.vue'),
        name: 'RoomReserve',
        meta: {
          name: '숙박 예약',
        },
      },
    ],
  },
  {
    path: '/restaurant',
    component: MobilePageLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/restaurant/index.vue'),
        name: 'RestaurantIndex',
        meta: {
          name: '외식',
        },
      },
    ],
  },
  {
    path: '/restaurant',
    component: SubDetail,
    children: [
      {
        path: ':idx',
        component: () => import('@/views/restaurant/view.vue'),
        name: 'RestaurantView',
        meta: {
          name: '외식',
        },
      },
      {
        path: 'reserve',
        component: () => import('@/views/restaurant/reserve.vue'),
        name: 'RestaurantReserve',
      },
    ],
  },
  {
    path: '/place',
    component: MobilePageLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/place/index.vue'),
        name: 'PlaceIndex',
        meta: {
          name: '관광',
        },
      },
    ],
  },
  {
    path: '/place',
    component: SubDetail,
    children: [
      {
        path: ':idx',
        component: () => import('@/views/place/view.vue'),
        name: 'PlaceView',
        meta: {
          name: '관광',
        },
      },
      {
        path: ':idx/:itemUid',
        component: () => import('@/views/place/detail.vue'),
        name: 'PlaceDetail',
        meta: {
          name: '관광',
        },
      },
      {
        path: ':idx/:itemUid/reserve',
        component: () => import('@/views/reserve/index.vue'),
        name: 'PlaceReserve',
        meta: {
          name: '관광 예약',
        },
      },
    ],
  },
  {
    path: '/leisure',
    component: MobilePageLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/leisure/index.vue'),
        name: 'LeisureIndex',
        meta: {
          name: '레저/체험',
        },
      },
    ],
  },
  {
    path: '/leisure',
    component: SubDetail,
    children: [
      {
        path: ':idx',
        component: () => import('@/views/leisure/view.vue'),
        name: 'LeisureView',
        meta: {
          name: '레저/체험',
        },
      },
      {
        path: ':idx/:itemUid',
        component: () => import('@/views/leisure/detail.vue'),
        name: 'LeisureDetail',
        meta: {
          name: '레저/체험',
        },
      },
      {
        path: 'reserve',
        component: () => import('@/views/reserve/index.vue'),
        name: 'LeisureReserve',
        meta: {
          name: '레저/체험 예약',
        },
      },
    ],
  },
  // {
  //   path: '/exciting',
  //   component: MobilePageLayout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/exciting/index.vue'),
  //       name: 'ExcitingIndex',
  //       meta: {
  //         name: '체험',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/exciting',
  //   component: SubDetail,
  //   children: [
  //     {
  //       path: ':idx',
  //       component: () => import('@/views/exciting/view.vue'),
  //       name: 'ExcitingView',
  //       meta: {
  //         name: '체험',
  //       },
  //     },
  //     {
  //       path: ':idx/:itemUid',
  //       component: () => import('@/views/exciting/detail.vue'),
  //       name: 'ExcitingDetail',
  //       meta: {
  //         name: '체험',
  //       },
  //     },
  //     {
  //       path: ':idx/:itemUid/reserve',
  //       component: () => import('@/views/reserve/index.vue'),
  //       name: 'ExcitingReserve',
  //       meta: {
  //         name: '체험 예약',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/goods',
  //   component: MobileSubLayout,
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/goods/index.vue'),
  //       name: 'GoodsIndex',
  //       meta: {
  //         name: '일반상품',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/goods',
  //   component: SubDetail,
  //   children: [
  //     {
  //       path: 'view/:uid',
  //       component: () => import('@/views/goods/view.vue'),
  //       name: 'GoodsView',
  //       meta: {
  //         name: '일반상품',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/goods',
  //   component: MobilePageLayout,
  //   children: [
  //     {
  //       path: 'reserve/:itemUid',
  //       component: () => import('@/views/goods/reserve.vue'),
  //       name: 'GoodsReserve',
  //       meta: {
  //         name: '일반상품 구매',
  //       },
  //     },
  //   ],
  // },
  {
    path: '/event',
    component: MobileSubLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/event/index.vue'),
        name: 'EventIndex',
        meta: {
          name: '이벤트',
        },
      },
    ],
  },
  {
    path: '/event',
    component: SubDetail,
    children: [
      {
        path: ':idx',
        component: () => import('@/views/event/view.vue'),
        name: 'EventView',
        meta: {
          name: '이벤트',
        },
      },
    ],
  },
  {
    path: '/direction',
    component: MobileSubLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/direction/index.vue'),
        name: 'Direction',
        meta: {
          name: '교통',
        },
      },
    ],
  },
  {
    path: '/mypage',
    component: MoblileIndex,
    children: [
      {
        path: 'main',
        component: () => import('@/views/mypage/index.vue'),
        name: 'MypageIndex',
      },
    ],
  },
  {
    path: '/mypage',
    component: MobileMypageLayout,
    children: [
      {
        path: 'order',
        component: () => import('@/views/mypage/order/index.vue'),
        name: 'mypageOrder',
      },
      {
        path: 'inquiry',
        component: () => import('@/views/mypage/inquiry/index.vue'),
        name: 'Inquiry',
      },
      {
        path: 'inquiry/detail/:postUid',
        component: () => import('@/views/mypage/inquiry/detail.vue'),
        name: 'InquiryDetail',
      },
      {
        path: 'notice',
        component: () => import('@/views/mypage/notice/index.vue'),
        name: 'Notice',
      },
      {
        path: 'notice/detail/:postUid',
        component: () => import('@/views/mypage/notice/detail.vue'),
        name: 'NoticeDetail',
      },
      {
        path: 'couponIndex',
        component: () => import('@/views/mypage/couponIndex.vue'),
        name: 'couponIndex',
      },
      {
        path: 'wish',
        component: () => import('@/views/mypage/wish.vue'),
        name: 'wish',
        meta: {
          name: '저장된 항목',
        },
      },
      {
        path: 'mypage/recent',
        component: () => import('@/views/mypage/recent.vue'),
        name: 'Recent',
      },
      {
        path: 'review/:optionName',
        component: () => import('@/views/mypage/review.vue'),
        name: 'MypageReview',
      },
      {
        path: 'policy',
        component: () => import('@/views/mypage/privacyPolicy.vue'),
        name: 'privacyPolicy',
      },
      {
        path: 'stipulation',
        component: () => import('@/views/mypage/stipulation.vue'),
        name: 'stipulation',
      },
      {
        path: 'point',
        component: () => import('@/views/mypage/point.vue'),
        name: 'PointHistory',
      },
      {
        path: 'allim',
        component: () => import('@/views/mypage/allim.vue'),
        name: 'Allim',
      },
    ],
  },
  {
    path: '/mypage',
    component: MobileMypageLayout,
    children: [
      {
        path: 'inquiry/write',
        component: () => import('@/views/mypage/inquiry/form.vue'),
        name: 'InquiryWrite',
      },
      {
        path: 'inquiry/update/:postUid',
        component: () => import('@/views/mypage/inquiry/form.vue'),
        name: 'InquiryUpdate',
      },
      {
        path: 'infoContact',
        component: () => import('@/views/mypage/infoContact.vue'),
        name: 'infoContact',
      },
      {
        path: 'infoIndex',
        component: () => import('@/views/mypage/infoIndex.vue'),
        name: 'infoIndex',
        meta: {
          name: '내 정보수정',
        },
      },
      {
        path: '/couponRegister',
        component: () => import('@/views/mypage/couponRegister.vue'),
        name: 'couponRegister',
      },
      {
        path: 'order/:uid',
        component: () => import('@/views/mypage/order/detail.vue'),
        name: 'OrderDetail',
      },
      {
        path: 'order/review/:uid',
        component: () => import('@/views/mypage/order/review.vue'),
        name: 'OrderReviewForm',
      },
    ],
  },
  {
    path: '/mypage',
    component: MobileSubLayout,
    children: [
      {
        path: 'search',
        component: () => import('@/views/mypage/search.vue'),
        name: 'DetailSearch',
      },
    ],
  },
  {
    path: '/completepayment',
    component: MobileSubLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/mypage/completePayment.vue'),
        name: 'Completepayment',
      },
    ],
  },
  {
    path: '/currentlocation',
    component: MobileSubLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/direction/currentLocation.vue'),
        name: 'currentlocation',
        meta: {
          name: '현재위치',
        },
      },
    ],
  },
  {
    path: '/termsofuse',
    component: SubDetail,
    children: [
      {
        path: '',
        component: () => import('@/views/index/termsofuse.vue'),
        name: '이용약관',
        meta: {
          name: '이용약관',
        },
      },
    ],
  },
  {
    path: '/servicecenter',
    component: SubDetail,
    children: [
      {
        path: '',
        component: () => import('@/views/index/customerServiceCenter.vue'),
        name: '고객센터',
        meta: {
          name: '고객센터',
        },
      },
    ],
  },
  {
    path: '/storeentry',
    component: SubDetail,
    children: [
      {
        path: '',
        component: () => import('@/views/index/storeentry.vue'),
        name: '입점안내',
        meta: {
          name: '입점안내',
        },
      },
    ],
  },
  {
    path: '/privacy',
    component: SubDetail,
    children: [
      {
        path: '',
        component: () => import('@/views/index/privacy.vue'),
        name: '개인정보',
        meta: {
          name: '개인정보',
        },
      },
    ],
  },
  {
    path: '/marketing',
    component: SubDetail,
    children: [
      {
        path: '',
        component: () => import('@/views/index/marketing.vue'),
        name: '마케팅',
        meta: {
          name: '마케팅',
        },
      },
    ],
  },
  {
    path: '/locationInfo',
    component: SubDetail,
    children: [
      {
        path: '',
        component: () => import('@/views/index/locationInfo.vue'),
        name: '위치정보',
        meta: {
          name: '위치정보',
        },
      },
    ],
  },
  {
    path: '/search',
    component: MobilePageLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/index/search.vue'),
        name: 'TotalSearch',
        meta: {
          name: '통합검색',
        },
      },
    ],
  },
  {
    path: '/aisearch',
    component: MobilePageLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/index/ai.vue'),
        name: 'AiSearch',
        meta: {
          name: 'ai',
        },
      },
    ],
  },
  {
    path: '/management-download',
    component: MobileLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/download/manager.vue'),
        name: '매니저다운로드',
        meta: {
          name: '매니저다운로드',
        },
      },
    ],
  },
  {
    path: '/download',
    component: MobileLayout,
    children: [
      {
        path: '',
        component: () => import('@/views/download/user.vue'),
        name: '다운로드',
        meta: {
          name: '다운로드',
        },
      },
    ],
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue'),
  },
  {
    path: '/payment/fail',
    component: () => import('@/views/error/payment.vue'),
    name: 'PaymentFail',
  },
  {
    path: '/payment/fail/full',
    component: () => import('@/views/error/payment.vue'),
    name: 'PaymentReserveFull',
  },
  {
    path: '/nice/accept',
    component: () => import('@/views/nice/accept.vue'),
    name: 'NiceAccept',
  },
  {
    path: '/nice/deny',
    component: () => import('@/views/nice/deny.vue'),
    name: 'NiceDeny',
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to: Route, from: Route) => {
    return {
      selector: '#app',
      offset: {
        x: 0,
        y: 0,
      },
      behavior: 'smooth',
    };
  },
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: any) => {
  await UserModule.GetUserInfo();
  if (UserModule.token) {
    checkToken().then(() => {
      next();
    }).catch(async () => {
      await UserModule.LogOut();
      router.push({ name: 'Login' });
    });
    return;
  }
  next();
});

export default router;
